import fr from './fr/translation.json';
// import en from './en/translation.json';

const resources = {
  fr: { translation: fr },
  // en: { translation: en },
};

export const languages = Object.keys(resources);

export default resources;

/*
t('name');
t('institute');
t('location');
t('networkBandwidth');
t('totalStorage');
t('storageTypes');
t('nodeTypes');
t('hasGPU');
*/
