import React from 'react';

import GlobalContextProvider from './src/components/GlobalContextProvider';
import './src/i18n';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    {element}
  </GlobalContextProvider>
);
