import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#093C5C',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#D9B313',
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff',
      alt: '#f0f0f0',
    },
  },
  typography: {
    fontSize: 14,
    h1: { fontSize: '3rem' },
    h2: { fontSize: '2.125rem' },
    h3: { fontSize: '1.5rem' },
    h4: { fontSize: '1.25rem' },
    h5: { fontSize: '1.125rem' },
    h6: { fontSize: '1rem' },
  },
  shape: {
    borderRadius: 8,
  },
});

export default theme;
