import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import resources, { languages } from './locales';

const [defaultLang] = languages;

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,

    lng: defaultLang,
    fallbackLng: defaultLang,
    supportedLngs: languages,
    debug: (process.env.NODE_ENV === 'development' && process.env.I18N_DEBUG),

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    keySeparator: false,
    nsSeparator: false,
  });

export default i18n;
