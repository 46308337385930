const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'HELP_OPEN': {
      return {
        ...state,
        help: payload,
      };
    }

    case 'HELP_CLOSE': {
      return {
        ...state,
        help: undefined,
      };
    }

    default:
      throw new Error('Bad Action Type');
  }
};

export default reducer;
